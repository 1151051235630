import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/repository/src/layouts/PageLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Cookies`}</h2>
    <p>{`Jacobs (“us”, “we”, or “our”) uses cookies on `}<a parentName="p" {...{
        "href": "/"
      }}>{`www.jacobsenforcement.com`}</a>{` (the “Service”). By
using the Service, you consent to the use of cookies. Our Cookies Policy explains what cookies are,
how we use cookies, how third parties we may partner with may use cookies on the Service, your
choices regarding cookies and further information about cookies.`}</p>
    <h2>{`What are cookies?`}</h2>
    <p>{`Cookies are small pieces of text sent by your web browser by a website you visit. A cookie file is
stored in your web browser and allows the Service or a third party to recognise you and make your
next visit easier and the Service more useful to you. Cookies can be “persistent” or “session”
cookies. When you use and access the Service, we may place a number of cookies in your web browser.
We use cookies for the following purposes: to enable certain functions of the Service, to provide
analytics, to store your preferences, to enable advertisements delivery, including behavioural
advertising. We use both session and persistent cookies on the Service and we use different types of
cookies to run the Service: Essential cookies. We may use essential cookies to authenticate users
and prevent fraudulent use of user accounts.`}</p>
    <p>{`Third party cookies In addition to our own cookies, we may also use various third parties cookies to
report usage statistics of the Service, deliver advertisements on and through the Service, and so
on.`}</p>
    <p>{`What are your choices regarding cookies if you’d like to delete cookies or instruct your web browser
to delete or refuse cookies, please visit the help pages of your web browser. Please note, however,
that if you delete cookies or refuse to accept them, you might not be able to use all of the
features we offer, you may not be able to store your preferences, and some of our pages might not
display properly.`}</p>
    <p>{`You can learn more about cookies at the following third party
websites:`}</p>
    <p>{`All About Cookies: `}<a parentName="p" {...{
        "href": "http://www.allaboutcookies.org"
      }}>{`www.allaboutcookies.org`}</a></p>
    <p>{`Please refer to our Privacy Notice.`}</p>
    <p>{`The Privacy Notice is effective as of 25th May 2018 and will remain in effect except with respect to
any changes in its provisions in the future, which will be in effect immediately after being posted
on this page.`}</p>
    <p>{`We reserve the right to update or change our Privacy Notice at any time and you should check this
Privacy Notice periodically. Your continued use of the Service after we post any modifications to
the Privacy Notice on this page will constitute your acknowledgment of the modifications and your
consent to abide and be bound by the modified Privacy Notice.`}</p>
    <p>{`If we make any material changes to this Privacy Notice, we will notify you either through the email
address you have provided us, or by placing a prominent notice on our website.`}</p>
    <h2>{`Log Data`}</h2>
    <p>{`Like many site operators, we collect information that your browser sends whenever you visit our Site
(“Log Data”).`}</p>
    <p>{`This Log Data may include information such as your computer’s Internet Protocol (“IP”) address,
browser type, browser version, and the pages of our Site that you visit, the time and date of your
visit, the time spent on those pages and other statistics. In addition, we may use third party
services such as Google Analytics that collect, monitor and analyze this. The Log Data section is
for businesses that use analytics or tracking services in websites or apps, like Google Analytics.
For the full disclosure section, create your own Privacy Policy.`}</p>
    <h2>{`Security`}</h2>
    <p>{`The security of your Personal Information is important to us, but remember that no method of
transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to
use commercially acceptable means to protect your Personal Information, we cannot guarantee its
absolute security.`}</p>
    <h2>{`Contact us`}</h2>
    <p>{`If you have any questions about this Cookies and Privacy Policy, please `}<a parentName="p" {...{
        "href": "/contact"
      }}>{`contact us`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      