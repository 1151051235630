import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import BaseMdxLayout from './BaseMdxLayout';
import { Heading1, Paragraph } from '../components/Typography';
import SEO from '../components/seo';
import { propOr } from 'ramda';

const PageLayout = ({ children, pageContext }) => {
  const { title, intro, seo = {} } = propOr({}, 'frontmatter', pageContext);

  return (
    <BaseMdxLayout
      heroContent={
        <>
          <SEO title={seo.title} description={seo.description} />
          <Heading1 fontSize='2.4rem' marginTop='120px' maxWidth='800px'>
            {title}
          </Heading1>
          <Paragraph fontSize='0.95rem' maxWidth='800px'>
            {intro}
          </Paragraph>
        </>
      }
      useRenderer={false}
    >
      {children}
    </BaseMdxLayout>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export const pageQuery = graphql`
  query MDXQuery($id: String!) {
    mdx(id: { eq: $id }) {
      id
      body
      frontmatter {
        title
        intro
      }
    }
  }
`;

export default PageLayout;
